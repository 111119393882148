<template>
    <div
        class="confirm-modal"
        @click.self="close()"
    >
        <div class="confirm-modal__dialog">
            <header class="confirm-modal__header">
                <h2>Request a withdrawal</h2>
                <span
                    class="confirm-modal__close"
                    @click="close()"
                />
            </header>
            <div
                v-if="loading"
                class="radius-loader--wrapper"
            >
                <div
                    class="background-for-radius-loader"
                >
                    <img
                        src="@/assets/img/svg/animation/LoaderRadius.svg"
                        alt="loader"
                    >
                </div>
            </div>
            <template v-else>
                <section
                    v-if="!!connectedMethods.length"
                    class="confirm-modal__content"
                >
                    withdrawal method:
                    <form-select
                        v-model="selectedMethod"
                        :field.sync="selectedMethod"
                        :value-select="selectedMethod"
                        :items="connectedMethods"
                        class="select_payment"
                        name="pay_method"
                        label="Payment Method"
                        :errors="validationMessage($v.selectedMethod)"
                        @change.native="$v.selectedMethod.$touch()"
                        @blur.native="$v.selectedMethod.$touch()"
                    />
                </section>
                <section
                    v-else
                    class="confirm-modal__content"
                >
                    You need to
                    <router-link
                        :to="{ 'name': 'profile_payment-method-add' }"
                    >
                        add a payment method
                    </router-link>
                </section>
            </template>
            <footer>
                <div class="confirm-modal__two-button-block payouts-buttons">
                    <button
                        class="btn-base btn-secondary"
                        @click.prevent="close()"
                    >
                        Cancel
                    </button>
                    <button
                        class="btn-base btn-main"
                        :disabled="!selectedMethod"
                        @click.prevent="submit()"
                    >
                        Submit
                    </button>
                </div>
            </footer>
        </div>
    </div>
</template>

<script>
import Api from '@/helpers/api/index.js';
import { eventBus } from '@/helpers/event-bus'
// Validation
import { validationMixin } from 'vuelidate'
import { validationMessage } from 'vuelidate-messages'
import formValidationMixin from '@/mixins/formValidationMixin'
import { validationRules } from '@/validation/request-payout/Rules'
import { formMessages } from '@/validation/request-payout/Messages'

import {
    getPaymentsListConnected
} from '@/services/payments';

export default {
    name: 'RequestPayouts',
    mixins: [
        validationMixin,
        formValidationMixin
    ],
    validations: {
        ...validationRules
    },
    data() {
        return {
            dialog: false,
            connectedMethods: {},
            selectedMethod: null,
            loading: false
        }
    },
    async created() {
        await this.getMethods()
        // eslint-disable-next-line prefer-destructuring
        this.selectedMethod = this.connectedMethods[0]?.value
    },
    methods: {
        validationMessage: validationMessage(formMessages),
        close() {
            this.$emit('close')
        },
        async getPaymentsListConnected() {
            let data
            try {
                data = await getPaymentsListConnected()
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
            return data
        },
        async getMethods() {
            this.loading = true
            const { short_list } = await this.getPaymentsListConnected()
            this.connectedMethods = short_list.map(({ title, value }) => ({
                text: title,
                value
            }))
            this.loading = false
        },
        getTitle(key) {
            switch (key) {
            case 'wire':
                return 'Bank Transfer methods:'
            case 'payoneer':
                return 'Payoneer method:'
            default:
                return ''
            }
        },
        async submit() {
            this.$v.$touch()
            if (!this.$v.$invalid) {
                this.loading = true
                await Api.post('/api/payout/create', { id: this.selectedMethod })
                    .then(() => {
                        eventBus.$emit('showSnackBar', 'Payment request has been created.', 'success');
                        this.close()
                    })
                    .catch((error) => {
                        eventBus.$emit('showSnackBar', error, 'info');
                        this.close()
                    }).finally(() => {
                        this.loading = false
                    })
            }
        }
    }
}
</script>

<style lang="scss" scoped >

.payouts-buttons button{
    width: 48%;
}
.payouts-radio_group{
    margin: 15px 0;
    padding-left: 10px;
    &-item {
        margin-bottom: 10px;
        &:last-of-type {
            margin-bottom: 0;
        }
    }
}
.select_payment {
    margin-top: 15px;
}
</style>
