<template>
    <div
        class="header-content__addition"
        :class="setContentAdditions"
    >
        <div
            v-if="addition.balance"
            class="header-content__balance"
        >
            <span class="header-content__addition-title">Balance</span>
            ${{ addition.balance }}
        </div>
        <div
            v-if="addition.paid"
            class="header-content__paid"
        >
            <span class="header-content__addition-title">Paid out</span>
            ${{ addition.paid }}
        </div>
        <!-- FILTERS -->
        <slot name="sorting" />
        <div
            class="filters-btn"
            :style="hasFilters ? 'color: #fff' : 'color: #9e9e9e'"
            @click="showFilters(hasFilters)"
        >
            <filter-outline
                size="40"
            />
            <span
                v-if="filtersParamsCount > 0"
                class="filters-btn__counter"
            >
                !
            </span>
        </div>
        <!-- FILTERS -->
        <div
            v-if="addition.report"
            class="header-content__report"
        >
            <a
                class="report-problem"
                @click="redirectToTheHelpCenter()"
            >
                <Bell />
                <span class="report-problem__text">Report a problem</span>
            </a>
            <!-- <report-a-problem />-->
        </div>
        <div
            v-if="addition.agreed"
            class="header-content__agreed"
        >
            Agreed
        </div>
        <div class="header-content__btn">
            <custom-button
                v-if="addition.btn_link"
                default
                class="button btn-base_colored sort-button"
                @on-btn-click="onBtnClick"
            >
                {{ addition.btn_text }}
            </custom-button>
        </div>
        <request-payouts
            v-if="showModal"
            @close="closeModal"
        />
    </div>
</template>

<script>
// import ReportAProblem from '@/components/common/ReportAProblem'
import RequestPayouts from '@/components/modals/RequestPayouts';
import Bell from 'mdi-vue/Bell'
import FilterOutline from 'mdi-vue/FilterOutline'

export default {
    name: 'HeaderContentAddition',
    components: {
        // ReportAProblem,
        RequestPayouts,
        Bell,
        FilterOutline
    },
    props: {
        addition: {
            type: Object,
            require: true
        },
        hasFilters: {
            type: Boolean,
            required: false,
            default: false
        },
        filtersParamsCount: {
            type: [Number, String],
            required: false,
            default: 0
        }
    },
    data() {
        return {
            showModal: false
        }
    },
    computed: {
        setContentAdditions() {
            return this.$route.name === 'orders'
                ? 'header-content__addition-orders'
                : ''
        }
    },
    methods: {
        onBtnClick() {
            if (this.$route.name === 'earnings') {
                this.showModal = true
                this.$emit('clearQuery')
            } else {
                this.$router.push({ name: this.addition.btn_link })
            }
        },
        closeModal() {
            this.showModal = false
        },
        redirectToTheHelpCenter() {
            this.$router.push({
                name: 'support_form'
            })
        },
        showFilters(preset) {
            this.$emit('showFilters', preset)
        }
    }
}
</script>

<style lang="scss" scoped>
.header-content {
    &__addition {
        display: flex;
        align-items: center;
        color: $white;

        &-title {
            font-size: 14px;
            font-weight: normal;
            margin-bottom: 10px;
        }
        &-orders {
            width: initial !important;
            align-items: center !important;
            margin-top: 0 !important;
            @media (max-width: 576px) {
                margin-top: 0
            }
        }
    }
    @media (max-width: 992px) {
        &__addition {
            width: 90%;
            flex-wrap: wrap;
            align-items: flex-start;
            margin-top: 25px;
        }
    }
    @media (max-width: 480px) {
        &__addition {
            .header-content__btn {
                margin-top: 0px;
            }
        }
    }
}

@media (max-width: 576px) {
    .header-content__btn {
        margin-bottom: 20px;
    }
}

.report-problem {
    display: flex;
    align-items: center;
    margin-right: 25px;
    cursor: pointer;
    padding: 15px 0;
    &__text {
        font-size: 14px;
    }
    .mdi {
        order: 2;
        margin-left: 10px;
        path {
            fill:#E94949;
        }
    }
}

</style>
